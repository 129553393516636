@import "./reset.css";
@import "./components/values";
@import "./components/colors.scss";

@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Playfair+Display:ital,wght@0,400;0,600;1,400&family=Source+Sans+Pro&display=swap');

html {
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 600;
    // font-family: 'Playfair Display', serif;
    font-family: 'Source Sans Pro', sans-serif;
}

a {
    color: unset;
    text-decoration: none;
}

.background {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
    }
}

.content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    align-content: center;

    height: 100%;
    width: 100%;

    @media (max-width: $mobile-width) {
        flex-direction: column;
    }

    .left, .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 44px 77px;
        z-index: 10;
        @media (max-width: $mobile-width) {
            padding: 22px 44px;
        }
    }
}

.title {
    font-weight: 600;
    font-size: 52px;
}

.description {
    margin-bottom: 55px;
    font-weight: 100;
    font-style: italic;
}

.grow {
    flex-grow: 1;
    height: auto;
    width: auto;
}

.brand-gradient-background {
    background: -webkit-gradient(linear, left top, left bottom, from($color-main), to($color-main-accent));
    background: linear-gradient(45 * 5deg, $color-main, $color-main-accent);
}

.blur-background {
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    @supports not (backdrop-filter: blur()) {
        background-color: transparentize($color: $color-dark, $amount: 0.6);
    }
}

.btn {
    display: block;
    width: fit-content;
    text-align: center;
    font-weight: bold;
    padding: 10px 22px;
    font-size: 16px;
    letter-spacing: 1px;
    // background-color: transparentize(black, 0.8);
    background-color: $color-light;
    color: $color-dark;
    font-family: 'Source Sans Pro', sans-serif;

    &:hover {
        // background-color: transparentize(black, 0.7);
        // background-color: darken($color: $color-light, $amount: 20%);
        background-color: transparent;
        color: $color-light;
        cursor: pointer;

        outline: dashed white 1px;
    }

    @media (max-width: $mobile-width) {
        width: auto;
    }
}

.next-section-indicator-wrapper{
    height: 0px;
}
.next-section-indicator{
    position: relative;
    bottom: -100%;
}

.disable-touch {
    pointer-events: none;
}